import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

import {createStorageProvider} from '../../utils/storageContext'
import {RequestStatus, wrapRequest} from '../../utils/wrapRequest'
import {noop} from '../../utils/helpers'
import {getRegionalSettings} from '../../utils/flowAPI'
import {reservationLocationsService} from '../../services/reservationLocationsService'
import {getReservationLocationsMock} from '../../editor/editorMocks/getReservationLocationsMock'
import {goToNewReservation, goToReservationDetails} from '../../utils/navigation'
import {ApprovalTextEditorState} from '../../utils/constants'

interface ReservationAddOnData {
  regionalSettings?: string
  compId: string
  getReservationLocationsStatus: RequestStatus
  reservationLocations: ReservationLocation[]
  approvalTextEditorState: ApprovalTextEditorState
  fitToContentHeight: boolean
}

interface ReservationAddOnActions {
  getReservationLocations: () => Promise<ReservationLocation[] | undefined>
  goToReservationsPage: (params: {
    reservationData: {
      reservationLocationId: string
      partySize: number
      startDate: Date
    }
  }) => void
  goToDetailsPage: (params: {
    reservationData: {
      startDate: Date
      partySize: number
      reservationLocationId: string
    }
  }) => void
}

export interface ReservationAddOnStorage extends ReservationAddOnData, ReservationAddOnActions {}

const defaultReservationAddOnStorage: ReservationAddOnStorage = {
  getReservationLocationsStatus: RequestStatus.DEFAULT,
  reservationLocations: [],
  goToReservationsPage: noop,
  goToDetailsPage: noop,
  getReservationLocations: noop,
  compId: '',
  approvalTextEditorState: ApprovalTextEditorState.unknown,
  fitToContentHeight: true,
}

const {withStorageProvider, useStorage} = createStorageProvider(defaultReservationAddOnStorage)

const initReservationAddOnStorage = (flowAPI: ControllerFlowAPI): ReservationAddOnStorage => ({
  ...defaultReservationAddOnStorage,
  regionalSettings: getRegionalSettings(flowAPI),
  getReservationLocations: wrapRequest(
    flowAPI,
    reservationLocationsService.getReservationLocations,
    'reservationLocations',
    'getReservationLocationsStatus',
  ),
  compId: flowAPI.controllerConfig.compId,
  goToReservationsPage: ({reservationData}) => goToNewReservation({flowAPI, reservationData}),
  goToDetailsPage: ({reservationData}) =>
    goToReservationDetails({
      flowAPI,
      reservationData,
    }),
})

const mockReservationAddOnStorage = (flowAPI: ControllerFlowAPI): ReservationAddOnStorage => {
  const initiatedStorage = initReservationAddOnStorage(flowAPI)

  return {
    ...initiatedStorage,
    compId: flowAPI.controllerConfig.compId,
    getReservationLocations: wrapRequest(
      flowAPI,
      getReservationLocationsMock,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
  }
}

export {
  initReservationAddOnStorage,
  mockReservationAddOnStorage,
  withStorageProvider as withReservationAddOnStorageProvider,
  useStorage as useReservationAddonStorage,
}
